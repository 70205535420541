import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { convertPermission } from "permissionUtils";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Eye, EyeOff } from "lucide-react";

const AddUser = ({ addUserToggle, async }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const history = useHistory();

  const toggle = () => addUserToggle();

  const AddUserSchema = Yup.object().shape({
    FullName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("This field is required"),
    Mobile: Yup.string()
      .matches(/^05\d{8}$/, "The mobile number must be exactly 10 digits and start with 05")
      .required("This field is required"),
    nationalID: Yup.string()
      .matches(/^\d{10}$/, "The national ID must be exactly 10 digits")
      .required("This field is required"),
    Password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .required("This field is required"),
    RePassword: Yup.string()
      .oneOf([Yup.ref("Password"), null], "Passwords must match")
      .required("This field is required"),
  });

  const userPermissions = convertPermission(JSON.parse(localStorage.getItem("roles")));

  return (
    <Modal className="modal-lg" isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle}>New User</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            FullName: "",
            Mobile: "",
            nationalID: "",
            Password: "",
            RePassword: "",
          }}
          validationSchema={AddUserSchema}
          onSubmit={(values, { setErrors }) => {
            const formData = new FormData();
            formData.append("name", values.FullName);
            formData.append("mobile_number", values.Mobile);
            formData.append("national_id", values.nationalID);
            formData.append("password", values.Password);
            formData.append("password_confirmation", values.RePassword);

            axios
              .post("https://althameen.net/dashboard/v1/users", formData)
              .then(() => {
                async();
                addUserToggle();
              })
              .catch((error) => {
                if (error.response?.data?.errors.mobile_number) {
                  setErrors({ Mobile: error.response.data.errors.mobile_number });
                } else if (error.response?.data.errors.national_id) {
                  setErrors({ nationalID: error.response.data.errors.national_id });
                }
              });
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row">
                {/* Left Column */}
                <div className="col-6">
                  <div className="form-group mb-4">
                    <label htmlFor="FullName">Full Name</label>
                    <Field as={Input} type="text" id="FullName" name="FullName" className={touched.FullName && errors.FullName ? "is-invalid" : ""} />
                    <ErrorMessage name="FullName" component="div" className="text-danger mt-1" />
                  </div>

                  {/* Password Field */}
                  <FormGroup>
                    <label>Password</label>
                    <InputGroup>
                      <Field as={Input} type={showPassword ? "text" : "password"} name="Password" className={errors.Password && touched.Password ? "is-invalid" : ""} />
                      <InputGroupText onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer" }}>
                        {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                      </InputGroupText>
                    </InputGroup>
                    <ErrorMessage name="Password" component="div" className="text-danger mt-1" />
                  </FormGroup>

                  {/* Confirm Password Field */}
                  <FormGroup>
                    <label>Confirm Password</label>
                    <InputGroup>
                      <Field as={Input} type={showConfirmPassword ? "text" : "password"} name="RePassword" className={errors.RePassword && touched.RePassword ? "is-invalid" : ""} />
                      <InputGroupText onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ cursor: "pointer" }}>
                        {showConfirmPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                      </InputGroupText>
                    </InputGroup>
                    <ErrorMessage name="RePassword" component="div" className="text-danger mt-1" />
                  </FormGroup>
                </div>

                {/* Right Column */}
                <div className="col-6">
                  <div className="form-group mb-4">
                    <label htmlFor="Mobile">Mobile</label>
                    <Field as={Input} type="tel" id="Mobile" name="Mobile" className={touched.Mobile && errors.Mobile ? "is-invalid" : ""} />
                    <ErrorMessage name="Mobile" component="div" className="text-danger mt-1" />
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="nationalID">National ID</label>
                    <Field as={Input} type="number" id="nationalID" name="nationalID" className={touched.nationalID && errors.nationalID ? "is-invalid" : ""} />
                    <ErrorMessage name="nationalID" component="div" className="text-danger mt-1" />
                  </div>
                </div>
              </div>

              <hr />

              <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                <Button onClick={toggle} className="bg-primary">
                  Close
                </Button>
                <Button type="submit" className="bg-primary">
                  Add
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddUser;
