import React from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { Formik, Field, useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
const UpdateUserModal = ({ isOpen, toggle, selectedUser, async }) => {
  const initialValues = {
    fullName: "",
    nationalId: "",
  }

  const { setErrors } = useFormik({})

  const validationSchema = Yup.object({
    fullName: Yup.string().required("First Name is required"),

    nationalId: Yup.string()
      .matches(/^\d{10}$/, "The national ID must be exactly 10 digits")
      .required("this field is required"),
  })

  const handleSubmit = values => {
    const data = {
      user_id: selectedUser.id,
      name: values.fullName,
      national_id: values.nationalId,
    }
    axios
      .put("https://althameen.net/dashboard/v1/users/update", data)
      .then(res => {

        console.log(res);
        async()
        toggle()
      })
      .catch(error => {
        if (error.response && error.response.data.errors.national_id) {
          setErrors({ nationalID: error.response.data.errors.national_id })
        }
      })
  }

  

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Update User</ModalHeader>
      <Formik
        initialValues={{
          fullName: selectedUser.name || "",
          nationalId: selectedUser.national_id || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label for="fullName">Full Name</Label>
                <Field
                  name="fullName"
                  as={Input}
                  id="fullName"
                  placeholder="Enter full name"
                  className={
                    touched.fullName && errors.fullName ? "is-invalid" : ""
                  }
                />
                {errors.fullName && touched.fullName && (
                  <div className="text-danger">{errors.fullName}</div>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="nationalId">National ID</Label>
                <Field
                  name="nationalId"
                  as={Input}
                  id="nationalId"
                  placeholder="Enter national ID"
                  className={
                    touched.nationalId && errors.nationalId ? "is-invalid" : ""
                  }
                />
                {errors.nationalId && touched.nationalId && (
                  <div className="text-danger">{errors.nationalId}</div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default UpdateUserModal
