import React, { useState, useEffect } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import AddARemove from "./AddARemove";
import { convertPermission } from "permissionUtils";
import Select from "react-select";

function AddTicketManual({ setDataValues, props, Qitem, dataAnswers, answers, setAuth }) {
  const [answerIds, setAnswerIds] = useState([1]);

  // Initialize answerIds based on answers or form length
  useEffect(() => {
    if (answers?.length > 0) {
      setAnswerIds(answers.map((_, index) => index + 1));
    }
  }, [answers]);

  useEffect(() => {
    const lengthValue = props.values[`Dropdown${Qitem.QID}-length`];
    if (lengthValue && lengthValue > answerIds.length) {
      setAnswerIds(Array.from({ length: lengthValue }, (_, i) => i + 1));
    }
  }, [props.values[`Dropdown${Qitem.QID}-length`], Qitem.QID, answerIds.length]);

  const userPermissions = convertPermission(JSON.parse(localStorage.getItem("roles")) || []);

  const removeChoice = (id) => {
    const keyPrefix = `${Qitem.AnswerType}${Qitem.QID}`;
    const lengthValue = props.values[`${keyPrefix}-length`];

    if (lengthValue) {
      setDataValues((prev) => ({
        ...prev,
        [`${keyPrefix}-Answer${id}`]: "",
        [`${keyPrefix}-RSelect${id}`]: "",
        [`${keyPrefix}-CSelect${id}`]: "",
        [`${keyPrefix}-Term${id}`]: "",
        [`${keyPrefix}-length`]: Math.max(lengthValue - 1, 1),
      }));
    } else {
      const answerKey = `${keyPrefix}`;
      const otherValue = props.values[`otherCheckBox${Qitem.QID}-${id}`];
      if (props.values[answerKey]) {
        props.values[answerKey] = props.values[answerKey].filter(
          (item) => item !== `Other|${otherValue}`
        );
      }
      setDataValues((prev) => ({
        ...prev,
        [`${keyPrefix}-Answer${id}`]: "",
        [`${keyPrefix}-RSelect${id}`]: "",
        [`${keyPrefix}-CSelect${id}`]: "",
        [`${keyPrefix}-Term${id}`]: "",
      }));
    }
    setAnswerIds((prev) => prev.filter((answerId) => answerId !== id));
  };

  const visitTypeOptions = [
    { value: null, label: " " },
    { value: "Foundation", label: "Foundation" },
    { value: "Columns", label: "Columns" },
    { value: "Slab", label: "Slab" },
    { value: "Waterproofing", label: "Waterproofing" },
    { value: "Additionalvisit", label: "Additional visit" },
    { value: "Final Visit", label: "Final Visit" },
    { value: "RD7", label: "RD7" },
  ];

  const ticketStatusOptions = [
    { value: null, label: " " },
    { value: "closed", label: "closed" },
    { value: "open", label: "open" },
  ];

  const hasPermission = userPermissions.R4.P >= 1 || userPermissions.R4.G === "1";

  return (
    <React.Fragment>
      {answerIds.map((id) => (
        <Container key={id} className="mt-5">
          <Row className="align-items-center">
            <Col sm={2} className="ms-5">
              <Label>Date:</Label>
            </Col>
            <Col sm={4}>
              <Input
                value={props.values[`${Qitem.AnswerType}${Qitem.QID}-Answer${id}`] || ""}
                type="date"
                max="2050-12-31"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                name={`${Qitem.AnswerType}${Qitem.QID}-Answer${id}`}
              />
              {props.errors[`${Qitem.AnswerType}${Qitem.QID}-Answer${id}`] && (
                <div className="error mt-1">
                  {props.errors[`${Qitem.AnswerType}${Qitem.QID}-Answer${id}`]}
                </div>
              )}
            </Col>

            {hasPermission && (
              <Col sm={5}>
                <Label className="d-flex align-items-center">
                  <span className="me-2">Visit Type:</span>
                  <Select
                    onChange={(selectedOption) =>
                      props.setFieldValue(
                        `${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`,
                        selectedOption?.value || ""
                      )
                    }
                    onBlur={props.handleBlur}
                    options={visitTypeOptions}
                    name={`${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`}
                    value={visitTypeOptions.find(
                      (option) =>
                        option.value ===
                        props.values[`${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`]
                    )}
                    className="form-control p-0"
                  />
                </Label>
                {props.errors[`${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`] && (
                  <div className="error mt-1">
                    {props.errors[`${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`]}
                  </div>
                )}
              </Col>
            )}
          </Row>

          <Row className="align-items-center mt-3">
            <Col sm={2} className="ms-5">
              <Label>Concept:</Label>
            </Col>
            <Col sm={4}>
              <Input
                name={`${Qitem.AnswerType}${Qitem.QID}-Term${id}`}
                type="textarea"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values[`${Qitem.AnswerType}${Qitem.QID}-Term${id}`] || ""}
              />
              {props.errors[`${Qitem.AnswerType}${Qitem.QID}-Term${id}`] && (
                <div className="error mt-1">
                  {props.errors[`${Qitem.AnswerType}${Qitem.QID}-Term${id}`]}
                </div>
              )}
            </Col>

            <Col sm={5}>
              <Label className="d-flex align-items-center">
                <span className="me-2">Ticket Status:</span>
                <Select
                  onChange={(selectedOption) =>
                    props.setFieldValue(
                      `${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`,
                      selectedOption?.value || ""
                    )
                  }
                  onBlur={props.handleBlur}
                  options={ticketStatusOptions}
                  name={`${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`}
                  value={ticketStatusOptions.find(
                    (option) =>
                      option.value ===
                      props.values[`${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`]
                  )}
                  className="form-control p-0"
                />
              </Label>
              {props.errors[`${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`] && (
                <div className="error mt-1">
                  {props.errors[`${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`]}
                </div>
              )}
            </Col>
          </Row>

          {props.values[`${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`] === "closed" && (
            <Row className="mt-3">
              <Col sm={2} className="ms-5">
                <Label>Closing Certificate:</Label>
              </Col>
              <Col sm={6}>
                <Input
                  name={`${Qitem.AnswerType}${Qitem.QID}-AnswerAR${id}`}
                  value={
                    props.values[`${Qitem.AnswerType}${Qitem.QID}-AnswerAR${id}`] || ""
                  }
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="textarea"
                />
                {props.errors[`${Qitem.AnswerType}${Qitem.QID}-AnswerAR${id}`] && (
                  <div className="error mt-1">
                    {props.errors[`${Qitem.AnswerType}${Qitem.QID}-AnswerAR${id}`]}
                  </div>
                )}
              </Col>
            </Row>
          )}

          <Row className="mt-3">
            <Col sm={3} className="ms-5">
              <AddARemove
                answerIds={answerIds}
                setAnswerIds={setAnswerIds}
                id={id}
                name={[
                  `${Qitem.AnswerType}${Qitem.QID}-Answer${id}`,
                  `${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`,
                  `${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`,
                  `${Qitem.AnswerType}${Qitem.QID}-Term${id}`,
                ]}
                removeChoice={removeChoice} // Corrected prop name
                props={props}
              />
            </Col>
          </Row>
        </Container>
      ))}
    </React.Fragment>
  );
}

export default AddTicketManual;