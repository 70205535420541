import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import axios from "axios"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Tooltip,
  Label,
} from "reactstrap"
import { Eye, EyeOff, Info } from "lucide-react"

const PasswordChangeModal = ({ isOpen, toggle, user_id }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  // Validation Schema with Yup
  const validationSchema = Yup.object().shape({
    password: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(/[a-z]/, "Password must contain at least one lowercase letter")
          .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
          .required("This field is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  })

  const handleSubmit = values => {
    
    const data = {
      user_id: user_id,
      password: values.password,
      password_confirmation: values.confirmPassword,
      client_sms: values.notify,
    }
    axios
      .put("https://althameen.net/dashboard/v1/users/change-password", data)
      .then(res => {
        res && toggle()
      })
      .catch(error => {})
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Password</ModalHeader>
      <Formik
        initialValues={{ password: "", confirmPassword: "", notify: false }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values ,handleChange}) => (
          <Form>
            <ModalBody>
              {/* Password Field */}
              <FormGroup>
                <label>Password</label>
                <InputGroup>
                  <Field
                    as={Input}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className={
                      errors.password && touched.password ? "is-invalid" : ""
                    }
                  />
                  <InputGroupText
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                  </InputGroupText>
                </InputGroup>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-danger mt-1"
                />
              </FormGroup>

              {/* Confirm Password Field */}
              <FormGroup>
                <label>Confirm Password</label>
                <InputGroup>
                  <Field
                    as={Input}
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? "is-invalid"
                        : ""
                    }
                    id="confirmPasswordField"
                  />
                  <InputGroupText
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{ cursor: "pointer" }}
                  >
                    {showConfirmPassword ? (
                      <EyeOff size={18} />
                    ) : (
                      <Eye size={18} />
                    )}
                  </InputGroupText>
                </InputGroup>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-danger mt-1"
                />
              </FormGroup>

              {/* Notify Client with SMS */}
              <FormGroup className="mt-2">
                <Label check>
                  <Input
                    type="checkbox"
                    // checked={values.notify}
                    name="notify"
                    onChange={handleChange}
                  />{" "}
                  Inform Client with SMS
                </Label>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Change
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default PasswordChangeModal
