import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"

const CustomInput = ({ field, form, ...props }) => {
  return <Input {...field} {...props} />
}

const EndUserAppSettings = () => {
  const [initialValues, setInitialValues] = useState({
    maxVisits: "",
    maxWaitingRequests: "",
    preBookingInterval: "",
    schedulingTimeframe: "",
    maxPostponement: "",
    weekendDays: [],
  })
  useEffect(() => {
    let data = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/System_Settings_List.php", data)
      .then(res => {
        console.log("res", res.data)
        const data = res.data["1"]
        setInitialValues({
          // maxOwners: data.MOwner,
          // maxCoordinators: data.MCoordinator,
          // maxViewers: data.MViewer,
          maxVisits: data.MVisit,
          maxWaitingRequests: data.WVisit,
          preBookingInterval: data.Prebooking,
          schedulingTimeframe: data.Scheduling,
          maxPostponement: data.Mpostponement,
          weekendDays: data.Weekend.split(","),
        })
        console.log("initialValues", initialValues)
      })
      .catch(err => {
        console.log("err", err)
      })
  }, [])

  const validationSchema = Yup.object({
    // maxOwners: Yup.number().required("Required").min(1, "Must be at least 1"),
    // maxCoordinators: Yup.number()
    //   .required("Required")
    //   .min(1, "Must be at least 1"),
    // maxViewers: Yup.number().required("Required").min(1, "Must be at least 1"),
    maxVisits: Yup.number().required("Required"),
    maxWaitingRequests: Yup.number().required("Required"),
    preBookingInterval: Yup.number().required("Required"),
    schedulingTimeframe: Yup.number().required("Required"),
    maxPostponement: Yup.number().required("Required"),
    weekendDays: Yup.array().min(1, "Select at least one day"),
  })

  const handleSubmit = values => {
    const data = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      MVisit: values.maxVisits,
      WVisit: values.maxWaitingRequests,
      Prebooking: values.preBookingInterval,
      Scheduling: values.schedulingTimeframe,
      Mpostponement: values.maxPostponement,
      Weekend: values.weekendDays.join(","),
    }

    axios
      .post("https://test.rdapp.net/api/Edit_System_Settings.php", data)
      .then(res => {
        console.log("res", res)
      })
      .catch(err => {
        console.log("err", err)
      })
    // Handle submission logic here
  }

  const handleCheckboxChange = e => {
    const { value } = e.target
    const currentValues = values.weekendDays || [] // Ensure it’s an array
    const newValues = currentValues.includes(value)
      ? currentValues.filter(item => item !== value) // Uncheck: remove value
      : [...currentValues, value] // Check: add value
    setFieldValue("weekendDays", newValues) // Formik’s method to update the field
  }

  return (
    <div className="page-content">
      <Container>
        <h3 className="mt-4 mb-4">End User App Settings</h3>
        <Formik
          initialValues={{ ...initialValues }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ values, errors, touched, handleChange, handleSubmit ,setFieldValue}) => (
            <Form onSubmit={handleSubmit}>
              <h5>Visit Requests</h5>
              <div className="mt-4 ms-2">
                <FormGroup className="d-flex align-items-center">
                  <Label className="col-5">
                    Maximum No. of Visits/Day/Inspector
                  </Label>
                  <Col sm={2} className="mx-2 mb-2">
                    <Input
                      name="maxVisits"
                      type="number"
                      value={values.maxVisits}
                      onChange={handleChange}
                      invalid={touched.maxVisits && errors.maxVisits}
                    />
                  </Col>
                  <h6>request</h6>
                  {touched.maxVisits && errors.maxVisits && (
                    <div className="text-danger">{errors.maxVisits}</div>
                  )}
                </FormGroup>
                <FormGroup className="d-flex align-items-center">
                  <Label className="col-5">
                    Maximum No. of Waiting Requests/Day/Inspector
                  </Label>
                  <Col sm={2} className="mx-2 mb-2">
                    <Input
                      name="maxWaitingRequests"
                      type="number"
                      value={values.maxWaitingRequests}
                      onChange={handleChange}
                      invalid={
                        touched.maxWaitingRequests && errors.maxWaitingRequests
                      }
                    />
                  </Col>
                  <h6>request</h6>
                  {touched.maxWaitingRequests && errors.maxWaitingRequests && (
                    <div className="text-danger">
                      {errors.maxWaitingRequests}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="d-flex align-items-center">
                  <Label className="col-5">Pre-booking Interval (Days)</Label>
                  <Col sm={2} className="mx-2 mb-2">
                    <Input
                      name="preBookingInterval"
                      type="number"
                      value={values.preBookingInterval}
                      onChange={handleChange}
                      invalid={
                        touched.preBookingInterval && errors.preBookingInterval
                      }
                    />
                  </Col>
                  <h6>days</h6>
                  {touched.preBookingInterval && errors.preBookingInterval && (
                    <div className="text-danger">
                      {errors.preBookingInterval}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="d-flex align-items-center">
                  <Label className="col-5">Scheduling Timeframe (Days)</Label>
                  <Col sm={2} className="mx-2 mb-2">
                    <Input
                      name="schedulingTimeframe"
                      type="number"
                      value={values.schedulingTimeframe}
                      onChange={handleChange}
                      invalid={
                        touched.schedulingTimeframe &&
                        errors.schedulingTimeframe
                      }
                    />
                  </Col>
                  <h6>days</h6>
                  {touched.schedulingTimeframe &&
                    errors.schedulingTimeframe && (
                      <div className="text-danger">
                        {errors.schedulingTimeframe}
                      </div>
                    )}
                </FormGroup>
                <FormGroup className="d-flex align-items-center">
                  <Label className="col-5">
                    Maximum Postponement Period (Days)
                  </Label>
                  <Col sm={2} className="mx-2 mb-2">
                    <Input
                      name="maxPostponement"
                      type="number"
                      value={values.maxPostponement}
                      onChange={handleChange}
                      invalid={
                        touched.maxPostponement && errors.maxPostponement
                      }
                    />
                  </Col>
                  <h6>days</h6>
                  {touched.maxPostponement && errors.maxPostponement && (
                    <div className="text-danger">{errors.maxPostponement}</div>
                  )}
                </FormGroup>
                <FormGroup className="d-flex align-items-center">
                  <Label className="col-3">Weekend Days</Label>
                  <div>
                    {[
                      { label: "Saturday", value: "6" },
                      { label: "Sunday", value: "7" },
                      { label: "Monday", value: "1" },
                      { label: "Tuesday", value: "2" },
                      { label: "Wednesday", value: "3" },
                      { label: "Thursday", value: "4" },
                      { label: "Friday", value: "5" },
                    ].map((day, key) => (
                      <Label className="me-2" key={key} check>
                        <input
                          type="checkbox"
                          name="weekendDays"
                          className="me-1 "
                          value={day.value}
                          onClick={(e) => {
                            const { value } = e.target
                            const currentValues = values.weekendDays || [] // Ensure it’s an array
                            const newValues = currentValues.includes(value)
                              ? currentValues.filter(item => item !== value) // Uncheck: remove value
                              : [...currentValues, value] // Check: add value
                              console.log("newValues", newValues)
                            setFieldValue("weekendDays", newValues) // Formik’s method to update the field
                          }}
                          checked={
                            values.weekendDays.includes(day.value) || false
                          }
                        />
                        {day.label}
                      </Label>
                    ))}
                  </div>
                  {touched.weekendDays && errors.weekendDays && (
                    <div className="text-danger">{errors.weekendDays}</div>
                  )}
                </FormGroup>
              </div>
              <div className="d-flex justify-content-end m-2">
                <Button type="submit" color="primary">
                  Apply
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  )
}

export default EndUserAppSettings
