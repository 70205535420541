import React from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { Formik, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import axios from "axios"

const ModifyModal = ({ isOpen, toggle, projectData, async, modifyOptions }) => {

  console.log("modifyOptions",modifyOptions);
  
  const initialValues = {
    role: "",
  }

  const validationSchema = Yup.object({
    role: Yup.string().required("Please select a role"),
  })

  const handleSubmit = (values, { setSubmitting }) => {
    let data = {
      user_id: projectData.user_id,
      project_id: projectData.project_id,
      role_id: values.role,
    }
    axios
      .put("https://althameen.net/dashboard/v1/projects/update-role", data)
      .then(response => {
        async()
        toggle()
      })
      .catch(error => {
        console.log("error", error)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Modify Role</ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleChange, isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormGroup className="d-flex ms-4" tag="fieldset">
                <h5>Change Role: </h5>
                <div className="d-flex" style={{ marginLeft: "30px" }}>
                  {modifyOptions.owner && (
                    <FormGroup className="me-3" check>
                      <Label check>
                        <Input
                          name="role"
                          type="radio"
                          value="1"
                          onChange={handleChange}
                        />
                        Owner
                      </Label>
                    </FormGroup>
                  )}
                  {modifyOptions.coordinator && (
                    <FormGroup className="me-3" check>
                      <Label check>
                        <Input
                          name="role"
                          type="radio"
                          value="2"
                          onChange={handleChange}
                        />
                        Coordinator
                      </Label>
                    </FormGroup>
                  )}
                  {modifyOptions.viewer && (
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="role"
                          type="radio"
                          value="3"
                          onChange={handleChange}
                        />
                        Viewer
                      </Label>
                    </FormGroup>
                  )}
                  {errors.role && touched.role && (
                    <div className="text-danger">{errors.role}</div>
                  )}
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting}>
                Update
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModifyModal