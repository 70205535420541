import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import PasswordChangeModal from "./PasswordChangeModal"
import UpdateUserModal from "./UpdateUserModal"
import ModifyModal from "./ModifyModal"
import ConfirmingAction from "common/ConfirmingAction"
import AddProject from "./AddProject"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Manage = ({ ManageToggle, selectedUser,editing, async,page }) => {



  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false)
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
  const [isModifyModalOpen, setModifyModalOpen] = useState(false)
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)
  const [isAddProjectModal, setAddProjectModal] = useState(false)
  const [currentUser,setCurrentUser] = useState(selectedUser) 

 
  

  useEffect(()=>{
    axios
    .get(`https://althameen.net/dashboard/v1/users`,{params:{page}})
    .then(res => {
      if (res.data.data && res.data.data.length > 0) {        
        setCurrentUser(res.data.data.filter(item => item.id === selectedUser.id)[0])
      } else {
        setUserData([])
      }
    })
    .catch(error => {
      console.log(error)
    })

  },[editing])

  const deleteFunc = () => {
    let data = {
      user_id: projectData.user_id,
      project_id: projectData.project_id,
      role_id: projectData.role,
    }
    axios
      .post("https://althameen.net/dashboard/v1/projects/delete", data)
      .then(res => {
        async()
        setConfirmModalOpen(!isConfirmModalOpen)
      })
      .catch(error => {
        
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
        })
      })
  }

  const sendRecovery = mobile => {
    axios
      .post("https://althameen.net/dashboard/v1/users/recovery-link", { mobile_number: mobile })
      .then(res => {
      toast.success("Recovery Link Sent Successfully", {
        autoClose: 1000,
      })
 
      })
      .catch(error => { 
      toast.error(error.response.data.message, {
        autoClose: 1000,
      })
      })
  }

  function convertRole(role) {
    if (role == "1") {
      return "Owner"
    } else if (role == "2") {
      return "Coordinator"
    } else {
      return "Viewer"
    }
  }

  const [projectData, setProjectData] = useState({})

  const [modifyOptions,setModifyOptions] = useState({
    owner: true,
    coordinator: true,
    viewer: true,
  })
  const checkModifyOptions = (data)=>{
    return  {
      owner: data.super_admin_maximum != data.super_admin_count,
      coordinator: data.admin_maximum != data.admin_count,
      viewer: data.viewer_maximum != data.viewer_count,
    }
  }
  
  return (
    <Modal className="modal-lg" isOpen={selectedUser} toggle={ManageToggle}>
      <ModalHeader>
        Manage Account
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={ManageToggle}
        />
      </ModalHeader>
      <ModalBody>
        <div className="ms-5 mb-3">
          <Row>
            <Col>
              <Label>Account ID : {selectedUser.id}</Label>{" "}
            </Col>
            <Col>
              <Label>Name : {selectedUser.name}</Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>National ID : {selectedUser.national_id}</Label>
            </Col>
            <Col>
              <Label>Mobile : {selectedUser.mobile_number}</Label>{" "}
            </Col>
          </Row>
        </div>
        <div dir="rtl" className="mb-3">
          <Button onClick={() => setPasswordModalOpen(true)}>
            Change User Password{" "}
          </Button>
          <Button onClick={() => sendRecovery(selectedUser.mobile_number)} className="me-2">
            Send Recovery Link
          </Button>
        </div>
        <div
          dir="rtl"
          className="mb-5"
         
        >
          <Button  onClick={() => setUpdateModalOpen(true)}>Update Data</Button>
        </div>

        <div className=" ms-3 d-flex justify-content-between align-items-center">
          <h5>Mange Projects :</h5>
          <Button
            onClick={() => {
              setAddProjectModal(true)
            }}
            className="p-1 mb-1"
          >
            Add
          </Button>
        </div>
        {/* Managed Project Table */}
        <Table className="ms-2 me-2 border  table-responsive project-list-table table-nowrap text-center align-middle">
          <thead>
            <th>#</th>
            <th>Ref</th>
            <th>Role</th>
            <th>Action</th>
          </thead>
          {currentUser?.projects?.map((item, key) => (
            <tbody key={key}>
              <td>{key + 1}</td>
              <td>{item.reference_number}</td>
              <td>{convertRole(item.role_id)}</td>
              <td>
                <span className="p-0 me-1">
                  <button
                    onClick={() => {
                      setModifyOptions(checkModifyOptions(item))
                      setModifyModalOpen(true),                       
                        setProjectData({
                          project_id: item.id,
                          user_id: selectedUser.id,
                          
                        })
                    }}
                    className="btn btn-primary btn-sm "
                  >
                    Modify
                  </button>
                </span>
                <span className="p-0">
                  <button
                    onClick={() => {
                      setProjectData({
                        project_id: item.id,
                        user_id: selectedUser.id,
                        role: item.role_id,
                      })
                      
                      setConfirmModalOpen(true)}}
                    className="btn btn-primary btn-sm"
                  >
                    Remove
                  </button>
                </span>
              </td>
            </tbody>
          ))}
        </Table>
        <ToastContainer />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={ManageToggle}>
          Close
        </Button>
      </ModalFooter>
      {/* Change Password */}
      {isPasswordModalOpen && (
        <PasswordChangeModal
          isOpen={isPasswordModalOpen}
          toggle={() => setPasswordModalOpen(!isPasswordModalOpen)}
          async={async}
          user_id={selectedUser.id}
        />
      )}

      {/* Update User Data */}
      {isUpdateModalOpen && (
        <UpdateUserModal
          isOpen={isUpdateModalOpen}
          toggle={() => setUpdateModalOpen(!isUpdateModalOpen)}
          async={async}
          selectedUser={selectedUser}
        />
      )}
      {/* Edit Modal */}
      {isModifyModalOpen && (
        <ModifyModal
          isOpen={isModifyModalOpen}
          toggle={() => setModifyModalOpen(!isModifyModalOpen)}
          projectData={projectData}
          modifyOptions={modifyOptions}
          async={async}
        />
      )}
      {/* Delete Modal */}
      {isConfirmModalOpen && (
        <ConfirmingAction
          ConfirmeModel={isConfirmModalOpen}
          Confirmetoggle={() => setConfirmModalOpen(!isConfirmModalOpen)}
          massege={"Are you sure you want to delete this Project?"}
          confirmFunc={deleteFunc}
          async={async}
        />
      )}
      {/* AddProject */}
      {isAddProjectModal && (
        <AddProject
          isOpen={isAddProjectModal}
          toggle={() => setAddProjectModal(!isAddProjectModal)}
          async={async}
          selectedUser={selectedUser}
        />
      )}
    </Modal>
  )
}

export default Manage
