import React, { useState, useEffect } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import { Button, Col, Container, Row, Table } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import VisitsFilter from "./VisitsFilter"
import LoadingInsider from "common/LoadingInsider"
import ViewModal from "./ViewModal"

const Visits = () => {
  const history = useHistory()

  // *********Document response*********/
  const [visitsList, setVisitsList] = useState([{}])

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** View Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)

  // ************************** Filter section *****************************
  const initialFilteredValues = {
  
    referenceNo: [],
    inspector: [],
    status: [],
    startDate: "",
    endDate: "",
    teamTask: false,
    dailyTask: false,
  }

  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ...filteredValues,
      page: page,
    }
    axios
      .post("https://test.rdapp.net/api/Visits/VisitList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        let array = Object.values(res.data)
        array.splice(-5)

        setVisitsList(array)
      })
  }, [filteredValues])

  const [type, setType] = useState()

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="Visits" />

          {/* ********************** Filter Section ***************************** */}
          <div className="d-flex col-12 justify-content-center">
            <VisitsFilter
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                setSelectedItem({})
                setType("Add")
                setViewModal(true)
              }}
            >
              Add Visit
            </Button>
          </div>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {/******************* table section**************************/}
                  <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Reference No.</th>
                        <th scope="col">Date</th>
                        <th scope="col">Stage</th>
                        <th scope="col">Inspector</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {/***********table body *****************/}
                    <tbody>
                      {visitsList.map((item, key) => (
                        <tr key={key}>
                          <td>{item.ReferenceNo}</td>
                          <td>{item.VisitDate}</td>
                          <td>{item.StageName}</td>
                          <td>{item.InspectorName}</td>
                          <td>{item.Status}</td>

                          <td>
                            <button
                              onClick={() => {
                                setSelectedItem(item)
                                setType("view")
                                setViewModal(true)
                              }}
                              className="btn btn-primary btn-sm dropdown-toggle"
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {visitsList.length === 0 && (
                    <LoadingInsider type="spin" color="gray" />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {visitsList.length > 1 && (
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          )}
          {viewModal && (
            <ViewModal
              selectedItem={selectedItem}
              toggle={() => setViewModal(!viewModal)}
              isOpen={ViewModal}
              type={type}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Visits
