import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import ModifyModal from "./ModifyModal"
import ConfirmingAction from "common/ConfirmingAction"
import axios from "axios"
import { convertObjectToArray } from "common/convertObjectToArray"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const AddProject = ({ toggle, isOpen, selectedUser, async }) => {
  const [isModifyModalOpen, setModifyModalOpen] = useState(false)
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)

  const [referencenumber, setReferenceNumber] = useState("")
  const [projectData, setProjectData] = useState({})
  const [errorMessage, setErrorMessage] = useState("")

  const [projectUsers, setProjectUsers] = useState({
    admin_count: "",
    admin_maximum: "",
    super_admin_count: "",
    super_admin_maximum: "",
    users: [],
    id: "",
    viewer_count: "",
    viewer_maximum: "",
  })

  const [message, setMessage] = useState("")
  const [message2, setMessage2] = useState("")
  const [modifyOptions,setModifyOptions] =useState({})

  useEffect(() => {
    if (referencenumber) {
      let data = {
        reference_number: referencenumber,
        user_id: selectedUser.id,
      }
      axios
        .post(
          "https://althameen.net/dashboard/v1/projects/by-reference-number",
          data
        )
        .then(res => {
          setProjectUsers(res.data.data)    
          
          setModifyOptions({
            owner: res.data.data.super_admin_maximum != res.data.data.super_admin_count,
            coordinator: res.data.data.admin_maximum != res.data.data.admin_count,
            viewer: res.data.data.viewer_maximum != res.data.data.viewer_count,
          })
          
          res.status !=200 &&  setMessage2(res.data.message)
          
        })
        .catch(err => {
          setMessage2(err.response?.data?.message)
        })
    }
  }, [referencenumber, async])



  const deleteFunc = () => {
    let data = {
      user_id: projectData.user_id,
      project_id: projectData.project_id,
      role_id: projectData.role,
    }
    axios
      .post("https://althameen.net/dashboard/v1/projects/delete", data)
      .then(res => {
        async()
        setConfirmModalOpen(!isConfirmModalOpen)
      })
      .catch(error => {
        setErrorMessage(error?.response?.data?.message)
      })
  }

  const formik = useFormik({
    initialValues: {
      role: "",
    },
    onSubmit: values => {
      let data = {
        reference_number: referencenumber,
        user_id: selectedUser.id,
        role_id: values.role,
      }

      try {
        axios
          .post("https://althameen.net/dashboard/v1/projects/store", data)
          .then(res => {
            console.log(res)

            async()
            toggle()
          })
          .catch(err => {
            setMessage(err?.response?.data?.message)
          })
      } catch (err) {
        console.log(err.message)
      }
    },
  })

  // useEffect(() => {
  //   axios.post("projects/by-reference-number", {
  //     reference_number: formik.values.status,
  //   })
  // }, [])

  


  function convertRole(role) {
    if (role == "1") {
      return "Owner"
    } else if (role == "2") {
      return "Coordinator"
    } else {
      return "Viewer"
    }
  }

  return (
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        Add Project
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={toggle}
        />
      </ModalHeader>

      <Col md={4} className="ms-4 my-2 d-flex align-items-center">
        <Label className="col-10">Reference Number : </Label>
        <div className="d-flex col-12 justify-content-start mb-2 mx-1">
          <div className="search-box me-4 col-12  ">
            <div className="position-relative mb-2  d-flex">
              <Input
                type="number"
                name="status"
                onChange={e => {
                  setTimeout(() => {
                    setReferenceNumber(e.target.value)
                  }, 2000)
                }}
                className="form-control border-dark "
                style={{ minHeight: "40px" }}
                placeholder="reference"
              />

              <i className="bx bx-search-alt search-icon " />
            </div>
          </div>
        </div>
      </Col>
      {message2 ? <p className="text-danger text-center ">{message2}</p> : null}
      {projectUsers.id && (
        <>
          <ModalBody>
            <h5>Capacity : </h5>
            <div className="ms-5 mb-3">
              <Row>
                <Col>
                  <Label>
                    Owner :{" "}
                    {projectUsers.super_admin_count +
                      "/" +
                      projectUsers.super_admin_maximum}
                  </Label>
                </Col>
                <Col>
                  <Label>
                    Coordinators :{" "}
                    {projectUsers.admin_count +
                      "/" +
                      projectUsers.admin_maximum}
                  </Label>
                </Col>
                <Col>
                  <Label>
                    Viewer :{" "}
                    {projectUsers.viewer_count +
                      "/" +
                      projectUsers.viewer_maximum}
                  </Label>
                </Col>
              </Row>
            </div>

            <div className=" ms-3 d-flex justify-content-between align-items-center">
              <h5>Mange Projects :</h5>
            </div>
            <Table className="ms-2 me-2 border border-2 table-responsive project-list-table table-nowrap text-center align-middle">
              <thead>
                <th>ID</th>
                <th>User</th>
                <th>Role</th>
                <th>Action</th>
              </thead>

              {convertObjectToArray(projectUsers?.users).length > 0 ? (
                convertObjectToArray(projectUsers?.users).map((item, key) => (
                  <tbody key={key}>
                    <td>{key + 1}</td>
                    <td>{item.name}</td>
                    <td>{convertRole(item.role_id)}</td>
                    <td>
                      <span className="p-0 me-1">
                        <button
                          onClick={() => {
                            setProjectData({
                              project_id: projectUsers.id,
                              user_id: item.id,
                              role: item.role_id,
                            })

                            setModifyModalOpen(true)
                          }}
                          className="btn btn-primary btn-sm "
                        >
                          Modify
                        </button>
                      </span>
                      <span className="p-0">
                        <button
                          onClick={() => {
                            setProjectData({
                              project_id: projectUsers.id,
                              user_id: item.id,
                              role: item.role_id,
                            })

                            setConfirmModalOpen(true)
                          }}
                          className="btn btn-primary btn-sm"
                        >
                          Remove
                        </button>
                      </span>
                    </td>
                  </tbody>
                ))
              ) : (
                <tbody>
                  <td colSpan="4">No Users</td>
                </tbody>
              )}
            </Table>

            <Form onSubmit={formik.handleSubmit}>
              <div className="d-flex justify-content-around">
                <span className="h6">Choose Role </span>
                {modifyOptions.owner && (
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="role"
                        onChange={formik.handleChange}
                        value={1}
                      />{" "}
                      Owner
                    </Label>
                  </FormGroup>
                )}

                {modifyOptions.coordinator && (
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="role"
                        onChange={formik.handleChange}
                        value={2}
                      />{" "}
                      Coordinator
                    </Label>
                  </FormGroup>
                )}
                {modifyOptions.viewer && (
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="role"
                        onChange={formik.handleChange}
                        value={3}
                      />{" "}
                      Viewer
                    </Label>
                  </FormGroup>
                )}
              </div>
              {message != "Project not found" && (
                <p className="text-danger text-end mt-3">{message}</p>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
            <Button color="primary" type="submit" onClick={formik.handleSubmit}>
              Add
            </Button>
          </ModalFooter>
        </>
      )}

      {isModifyModalOpen && (
        <ModifyModal
          isOpen={isModifyModalOpen}
          toggle={() => setModifyModalOpen(!isModifyModalOpen)}
          projectData={projectData}
          async={async}
          modifyOptions={modifyOptions}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmingAction
          ConfirmeModel={isConfirmModalOpen}
          Confirmetoggle={() => setConfirmModalOpen(!isConfirmModalOpen)}
          massege={"Are you sure you want to delete this Project?"}
          confirmFunc={deleteFunc}
          async={async}
          errorMessage={errorMessage}
        />
      )}
    </Modal>
  )
}

export default AddProject
