import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import Select from "react-select"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"

const ViewModal = ({ isOpen, toggle, selectedItem, type }) => {
  
  const [stages, setStages] = useState([])

  const [inspectors, setInspectors] = useState([])

  const initialValues = {
    stage: selectedItem?.StageID || "",
    date: selectedItem?.VisitDate || "",
    inspector: selectedItem?.InspectorID || "",
    status: selectedItem?.StatusID || "",
    comment: "",
  }

  const validationSchema = Yup.object({
    stage: Yup.object().nullable().required("Stage is required"),
    date: Yup.date().required("Date is required"),
    inspector: Yup.object().nullable().required("Inspector is required"),
    status: Yup.object().nullable().required("status is required"),
    comment: Yup.string().when("status", {
      is: status => status && status.value === "Hold",
      then: Yup.string()
        .required("Comment is required when status is Hold")
        .max(500, "Maximum 500 characters allowed"),
      otherwise: Yup.string().max(500, "Maximum 500 characters allowed"),
    }),
  })

  const handleSubmit = values => {
    console.log(values)
    toggle() // Close the modal on successful submit
  }

  const [statusOptions, setStatusOptions] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    // ************* Fetching Types List *****************
    axios
      .post("https://test.rdapp.net/api/Stages/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        array = array.filter(
          item => item.StageID !== "10" && item.StageID !== "11"
        )
        array = array.map(item => ({
          value: item.StageID,
          label: item.StageName,
        }))
        setStages(array)
      })
      .catch(error => {
        console.log(error)
      })
    // ************* Fetching Inspectors List *****************
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setInspectors(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })

    // ************* Fetching Status List *****************
    axios
      .post("https://test.rdapp.net/api/Visits/VisitStatus.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        const options = []
        res.data.StatusList.map(item => {
          options.push({ value: item.StatusID, label: item.Status })
        })

        setStatusOptions(options)
      })
      .catch(err => console.log(err))
  }, [])

  
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>view Visit</ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label for="stage">Stage</Label>
                  <Select
                    id="stage"
                    options={stages}
                    value={
                      stages?.find(
                        option => option.value === values?.["stage"]
                      ) || ""
                    }
                    onChange={option => setFieldValue("stage", option)}
                    onBlur={handleBlur("stage")}
                    isClearable
                  />
                  {touched.stage && errors.stage && (
                    <div className="text-danger">{errors.stage}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="date">Date</Label>
                  <Input
                    type="date"
                    id="date"
                    name="date"
                    value={values.date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.date && !!errors.date}
                  />
                  {touched.date && errors.date && (
                    <div className="text-danger">{errors.date}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="inspector">Inspector</Label>
                  <Select
                    id="inspector"
                    options={inspectors}
                    value={
                      inspectors?.find(
                        option => option.value === values?.["inspector"]
                      ) || ""
                    }
                    onChange={option => setFieldValue("inspector", option)}
                    onBlur={handleBlur("inspector")}
                    isClearable
                  />
                  {touched.inspector && errors.inspector && (
                    <div className="text-danger">{errors.inspector}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="visitStatus">Status</Label>
                  <Select
                    id="visitStatus"
                    options={statusOptions}
                    value={
                      statusOptions?.find(
                        option => option.value === values?.["status"]
                      ) || ""
                    }
                    onChange={option => setFieldValue("status", option)}
                    onBlur={handleBlur("status")}
                    isClearable
                  />
                  {touched.status && errors.status && (
                    <div className="text-danger">{errors.status}</div>
                  )}
                </FormGroup>
                {values.status && type == "view" && (
                  <FormGroup>
                    <Label for="comment">Comment From Client</Label>
                    <Input
                      type="textarea"
                      id="comment"
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      invalid={touched.comment && !!errors.comment}
                    />
                    {touched.comment && errors.comment && (
                      <div className="text-danger">{errors.comment}</div>
                    )}
                  </FormGroup>
                )}
                {values.status && (
                  <FormGroup>
                    <Label for="comment">Comment to Client</Label>
                    <Input
                      type="textarea"
                      id="comment"
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.comment && !!errors.comment}
                    />
                    {touched.comment && errors.comment && (
                      <div className="text-danger">{errors.comment}</div>
                    )}
                  </FormGroup>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export default ViewModal
