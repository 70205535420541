import axios from "axios"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"
import Switch from "react-switch"


const FilterRD3 = ({ filteredValues, setFilteredValues, setAuth }) => {
  //   console.log("filteredValues ------------->", filteredValues)

  const history = useHistory()

  const [users, setUsers] = useState([])

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange


  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }


  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Catagory:"Inspection"    
    }
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })

    axios
      .post("https://test.rdapp.net/api/OpenAccess/TypeList", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)
        setCategoryList(
          array.map(item => ({ value: item.BCID, label: item.Name }))
        )
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [statusOptions,setStatusOptions] =useState([]) 
  

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
     
    }

    axios
      .post("https://test.rdapp.net/api/RD3/RD3Status.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        const options=[]
        Object.keys(res.data).map((key)=>{
          if(Number(key)) {
          options.push({value:key,label:res.data[key].Name})}
        })   
        

        setStatusOptions(options)
      })
      .catch(err => console.log(err))
  }, [])

  const [Adminchecked, setAdminChecked] = useState(false)
  const handleAdminChange = () => {
    setAdminChecked(!Adminchecked)
    setFilteredValues({
      ...filteredValues,
      inactive: !Adminchecked,
    })
  }


  return (
    <>
      <Row className="mb-3">
        {/* ******************** Report ID  *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    reportID: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    reportID: allItems,
                  })
                }
                type="number"
                name="reportID"
                placeholder="Report ID ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>

        {/* ******************** RD3 Status *************************** */}
        {/* <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 d-flex">
              <Input
                type="select"
                name="RD3Status"
                onChange={e => {
                  // console.log(e.target.value)
                  setFilteredValues({
                    ...filteredValues,
                    RD3Status: e.target.value,
                  })
                }}
                className="form-control border-dark"
                style={{ minHeight: "40px" }}
              >
                <option selected value="">
                  RD3 Status ...
                </option>
                {statusOptions.map((status, i) => (
                  <option key={i} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Input>
              <i className="bx bx-search-alt search-icon " />
            </div>
          </div>
        </Col> */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={statusOptions}
                name="RD3Status"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.RD3Status}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, RD3Status: e })
                }}
                classNamePrefix="select"
                placeholder="RD3 Status ..."
              />
            </div>
          </div>
        </Col>


        {/* ******************** Reference No *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                type="number"
                name="referenceNo"
                placeholder="Reference No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>

      

        {/* ******************** Inspector *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={users}
                name="inspector"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.inspector}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, inspector: e })
                }}
                classNamePrefix="select"
                placeholder="Inspector ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Final Visit Date *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 d-flex form-control border-dark ">
              <i className="bx bx-search-alt search-icon " />

              <DatePicker
                isClearable
                className="border-0"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                  setFilteredValues({
                    ...filteredValues,
                    startDate: update[0] ? convertDate(update[0]) : null,
                    endDate: update[1] ? convertDate(update[1]) : null,
                  })
                }}
                // withPortal
                placeholderText="Final Visit Date ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Category *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={categoryList}
                name="category"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.category}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, category: e })
                }}
                classNamePrefix="select"
                placeholder="Category ..."
              />
            </div>

          </div>
        </Col>
        {/* ******************** switch  *************************** */}
        <Col sm={3}>
            <div  className="mt-2 ms-4">
                    <label>inactive RD3</label>
                    <Switch
                      onChange={handleAdminChange}
                      checked={Adminchecked}
                      onColor="#4458b8"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.3)"
                      height={15}
                      width={42}
                      className="react-switch ms-2"
                      id="material-switch"
                    />
               </div> 
        </Col>
      </Row>
    </>
  )
}

export default FilterRD3
