import React, { useEffect, useState } from "react"
import { Button, Form, FormGroup, Col } from "reactstrap"
import AddARemove from "../Add several Tickets/AddARemove"
import { vacateChildNodes } from "common/FunctionsHelper"

const OtherChoice = props => {

  const [disabled, setDisabled] = useState(true)

  let initalAnswers = []

  const [answerIds, setAnswerIds] = useState([1])

  useEffect(() => {
    if (props.props.values[`${props.name}-length`] > answerIds.length) {
      for (let i = 1; i < props.props.values[`${props.name}-length`]; i++) {
        initalAnswers.push(i)
      }
      setAnswerIds(initalAnswers)
      setDisabled(false)
    }
  }, [props.props.values[`${props.name}-length`]])

  const removeChoise=(id)=>{
    if (props.props.values[`${props.name}-length`]) {
      props.setDataValues(prevDataValues => ({
        ...prevDataValues,
        [`${props.name}-${id}`]: "",
      }))
    } else if ( props.props["values"] ) {
      props.props.values[
        `${props.Qitem.AnswerType}${props.Qitem.QID}`
      ] = props.props?.values[
        `${props.Qitem.AnswerType}${props.Qitem.QID}`
      ]?.filter(
        item =>
          item !==
          `Other|${props.props.values[`otherCheckBox${props.Qitem.QID}-${id}`]}`
      )
      props.props.values[`${props.name}-${id}`] = ""
    }
  }
 
  

  return (
    <>
      <div className="mt-2">
        <input
          type="Checkbox"
          id="others"
          onClick={e => {
            setDisabled(!e.target.checked)
            if (e.target.checked !== true) {
              vacateChildNodes(e, "otherCheckbox" + props.QID)
              props.props.values[`${props.name}`] = ""
              answerIds.forEach(id => {
                removeChoise(id)
              })
            }
          }}
          defaultChecked={props.props.values[`${props.name}-${1}`]}
        />
        <label htmlFor="others" className="ms-1">
          {props.label}
        </label>
      </div>
      {/* other checkbox text  */}
      <div id={"otherCheckbox" + props.QID}>
        {answerIds.map((id, key) => (
          <FormGroup key={key} className="d-flex">
           

            <input
              disabled={disabled}
              onChange={props.props.handleChange}
              type="text"
              id="other"
              name={`${props.name}-${id}`}
              style={{ height: "24px" }}
              defaultValue={props.props.values[`${props.name}-${id}`]}
              className="me-5"
            />

            <AddARemove
              answerIds={answerIds}
              setAnswerIds={setAnswerIds}
              id={id}
              name={[`${props.name}-${id}`]}
              props={props.props}
              removeChoise={removeChoise}
              
            />
          </FormGroup>
        ))}
      </div>
    </>
  )
}

export default OtherChoice
