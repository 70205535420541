import axios from "axios"
import React, { useEffect, useState } from "react"
import { Col, Input, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"

const FilterUsers = ({ filteredValues, setFilteredValues }) => {
  const history = useHistory()

  const [users, setUsers] = useState([])
  const [roles, setRoles] = useState([])

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://test.rdapp.net/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(err => {
        console.log(err)
      })


  }, [])

  // console.log("filteredValues", filteredValues)



  if (auth) {
    return <UnAuthorizedComponent />
  }

  const handleInputChange = (e, field) => {
    const value = e.target.value
    setTimeout(() => {
      setFilteredValues(prevValues => ({
        ...prevValues,
        [field]: value,
      }))
    }, 1000)
  }

  return (
    <Row>
      <Col md={4}>
        <div className="d-flex col-12 justify-content-start mb-2 mx-1">
          <div className="search-box me-4 col-12 ">
            <div className="position-relative mb-2 d-flex">
              <Input
                type="number"
                name="reference_number"
                onChange={e => handleInputChange(e, "reference_number")}
                className="form-control border-dark"
                style={{ minHeight: "40px" }}
                placeholder="reference number"
              />
              <i className="bx bx-search-alt search-icon " />
            </div>
          </div>
        </div>
      </Col>
      <Col md={4}>
        <div className="d-flex col-12 justify-content-start mb-2 mx-1">
          <div className="search-box me-4 col-12 ">
            <div className="position-relative mb-2 d-flex">
              <Input
                type="number"
                name="mobile_number"
                onChange={e => handleInputChange(e, "mobile_number")}
                className="form-control border-dark"
                style={{ minHeight: "40px" }}
                placeholder="mobile"
              />
              <i className="bx bx-search-alt search-icon " />
            </div>
          </div>
        </div>
      </Col>
      <Col md={4}>
        <div className="d-flex col-12 justify-content-start mb-2 mx-1">
          <div className="search-box me-4 col-12 ">
            <div className="position-relative mb-2 d-flex">
              <Input
                type="text"
                name="name"
                onChange={e => handleInputChange(e, "name")}
                className="form-control border-dark"
                style={{ minHeight: "40px" }}
                placeholder="Name"
              />
              <i className="bx bx-search-alt search-icon " />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default FilterUsers
