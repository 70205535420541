import React, { useState, useEffect } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  Label,
  Input,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Dropdown,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import ViewRD3Report from "./ViewRD3Report"
import FilterRD3 from "./FilterRD3"
import LoadingInsider from "common/LoadingInsider"
import ApproveMainModal from "../MangedApprove/ApproveMainModal"
import { usePrevious } from "common/CustomHooks"
import AsignRole from "./AsignRole"
import Comunication from "./Communication"

const RD3Reports = () => {
  const history = useHistory()

  //Manged Approved
  const [menu, setMenu] = useState(false)
  const [approvedModal, setapprovedModal] = useState(false)
  const toggleApproved = () => setapprovedModal(!approvedModal)

  // *********Document response*********/
  const [reportData, setReportData] = useState([])
  const [editing, setEditing] = useState(false)
  const [cleanReport, setCleanReport] = useState(false)

  const async=()=>{
    setEditing(!editing)
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )

  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** View Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)
  const viewToggle = () => {
    setViewModal(!viewModal)
  }

  // ************************** Filter section *****************************
  const initialFilteredValues = {
    reportID: [],
    RD3Status: [],
    referenceNo: [],
    inspector: [],
    category: [],
    startDate: "",
    endDate: "",
    inactive: "",
  }

  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  const prevFilteredValues = usePrevious(filteredValues)
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
    }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      Page: page,
      ReportID: filteredValues.reportID.join(","),
      ReferenceNo: filteredValues.referenceNo.join(","),
      Category: filteredValues.category.map(item => item.value).join(","),
      RD3Status: filteredValues.RD3Status.map(item => item.value).join(","),

      Inspector: filteredValues.inspector.map(item => item.value).join(","),
      Inactive: filteredValues.inactive ? 1 : 2,

      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
    }

    // console.log("formData", formData)
    axios
      .post("https://test.rdapp.net/api/RD3/RD3List.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        setTotalPages(res.data.TotalPages)
        setCleanReport(res.data.cleanReport)
        array.splice(-5)
        setReportData(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [editing, page, filteredValues])

  // ***************** Asign User ***************************//
  const [asign, setAsign] = useState(false)
  const toggleAsignUser = () => {
    setAsign(!asign)
  }
  const [asignData, setAsignData] = useState({})

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  const [CommunicationData,setCommunicationData] = useState({})
  const [CommunicationModal,setCommunicationModal] = useState(false)
  const toggleCommunicationData = () => setCommunicationModal(!CommunicationModal)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="RD-3 Reports" />

          {/* ********************** Filter Section ***************************** */}
          <div className="d-flex col-12">
            <FilterRD3
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />
            <Dropdown
              isOpen={menu}
              style={{ height: "40px" }}
              className="d-inline-block d-flex ms-auto col-1 dropdown-toggle justify-content-end  me-3"
            >
              <DropdownToggle
                className="btn header-item  "
                id="page-header-user-dropdown"
                tag="button"
                style={{ height: "15px" }}
              >
                <i className="bx bx-cog h3" onClick={() => setMenu(!menu)} />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem tag="a" href="#" onClick={toggleApproved}>
                  Auto Approved
                </DropdownItem>
                <div className="dropdown-divider" />
                {userPermissions.R3.G === "1" &&
                  userPermissions.R1.G === "1" && (
                    <DropdownItem
                      tag="a"
                      href="#"
                      className="d-flex align-items-center"
                    ></DropdownItem>
                  )}
              </DropdownMenu>
            </Dropdown>
          </div>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {/******************* table section**************************/}
                  <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Report Id</th>
                        <th scope="col">Category</th>
                        <th scope="col">Reference No.</th>
                        <th scope="col">Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Inspector</th>
                        <th scope="col">Status</th>
                        <th>History</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {/***********table body *****************/}
                    <tbody>
                      {reportData.map((item, key) => (
                        <tr key={key}>
                          <td>{item.RD3ID}</td>
                          <td>
                            <i
                              className={
                                item.Category === "Residential"
                                  ? "fas fa-home"
                                  : item.Category === "Hotels"
                                  ? "fas fa-hotel"
                                  : item.Category === "less than 23 meters"
                                  ? "fas fa-building"
                                  : item.Category === "Commercial Centers"
                                  ? "fas fa-store"
                                  : item.Category === "Entertainment Services"
                                  ? "fas fa-skating"
                                  : item.Category === "High Rise Towers"
                                  ? "fas fa-building"
                                  : item.Category === "High risk buildings"
                                  ? "fas fa-building"
                                  : item.Category === "Motels"
                                  ? "fas fa-hotel"
                                  : item.Category === "Educational"
                                  ? "fas fa-school"
                                  : item.Category ===
                                    "Gathering buildings Mosques"
                                  ? "fas fa-mosque"
                                  : item.Category ===
                                    "Business Buildings (Airports, Banks, TV Stations Post Offices)"
                                  ? "fas fa-building"
                                  : item.Category ===
                                    "Gathering buildings Sports Premises"
                                  ? "fas fa-running"
                                  : item.Category === "Warehouses"
                                  ? "fas fa-warehouse"
                                  : item.Category === "Industrial"
                                  ? "fas fa-industry"
                                  : item.Category ===
                                    "Furnished Hotel Apartments"
                                  ? "fas fa-hotel"
                                  : item.Category ===
                                    "Gathering buildings Wedding Halls Cinemas Theaters"
                                  ? "fas fa-warehouse"
                                  : item.Category === "Hospitals"
                                  ? "fas fa-hospital-alt"
                                  : item.Category === "Healthcare Centers"
                                  ? "fas fa-hospital"
                                  : item.Category === "Telecommunication Towers"
                                  ? "fas fa-broadcast-tower"
                                  : "fas fa-building"
                              }
                              id="categorytooltip"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="categorytooltip"
                            >
                              {item.Category || "N/A"}
                            </UncontrolledTooltip>
                          </td>
                          <td>{item.ReferenceNo}</td>
                          <td>{item.Type}</td>
                          <td>{item.CreatedAt}</td>
                          <td>{item.InspectionName}</td>
                          <td>
                            <span
                            // className={
                            //   item.Status == "Approved"
                            //     ? "badge bg-success"
                            //     : item.Status == "Rejected"
                            //     ? "badge bg-danger"
                            //     : item.Status == "Pending"
                            //     ? "badge bg-warning"
                            //     : item.Status == "Manual" &&
                            //       "badge bg-primary"
                            // }
                            >
                              {item.ReportStatus}
                            </span>
                          </td>
                          <td>
                            <Link to="#" id={`hs${item.ReferenceNo}`}>
                              <i className="bx bx-calendar h3 text-primary" />
                            </Link>
                            <UncontrolledTooltip
                              innerClassName=" custom-tooltip"
                              className="mt-2 custom-tooltip"
                              // autohide={true}
                              placement="bottom"
                              target={`hs${item.ReferenceNo}`}
                            >
                              {item.SHistory.map((history, key) => (
                                <div key={key} className="d-flex mb-2">
                                  <div className="me-4">{history.Status}</div>
                                  <div>{history.date}</div>
                                </div>
                              ))}
                            </UncontrolledTooltip>
                          </td>
                          <td>
                            <UncontrolledDropdown className="btn-group bg-primary">
                              <button
                                onClick={() => {
                                  setSelectedItem(item)
                                  viewToggle()
                                }}
                                className="btn btn-primary btn-sm dropdown-toggle"
                              >
                                View
                              </button>
                              <DropdownToggle
                                tag="a"
                                to="#"
                                className="card-drop"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                              </DropdownToggle>
                              <DropdownMenu  style={{zIndex:"200"}} className="dropdown-menu-end">
                               { console.log("item.StatusID",item.StatusID)
                               }
                                
                                {(item.StatusID=="1"||item.StatusID=="2")&&(userPermissions?.R31?.P === "4" ||
                                  userPermissions?.R32?.P === "4") && (
                                  <DropdownItem
                                    onClick={() => {
                                      setSelectedItem(item)
                                      toggleAsignUser()
                                      setAsignData({
                                        title: "Asign Inspector",
                                        api: "",
                                        selectedItem: item,
                                      })
                                    }}
                                  >
                                    Asign Inspector
                                  </DropdownItem>
                                )}
                                {

                                (item.StatusID!="1"&&item.StatusID!="2")&&
                                (userPermissions?.R31?.P === "4" ||
                                  userPermissions?.R32?.P === "4") && (
                                  <DropdownItem
                                    onClick={() => {
                                      setAsignData({
                                        title: "Change Responsible",
                                        api: "",
                                        selectedItem: item,
                                      })
                                      toggleAsignUser()
                                    }}
                                  >
                                    Change Responsible
                                  </DropdownItem>
                                )}

                       {   item.Name&&      <DropdownItem
                                  onClick={() => {                                   
                                    toggleCommunicationData()
                                    setCommunicationData({                                      
                                      name: item.Name,
                                      mobile: item.Mobile,
                                    })
                                  }}
                                >
                                  Client Communication Details
                                </DropdownItem>}
                                {/* {(userPermissions.R24.P === "3" ||
                                    userPermissions.R24.P === "4") && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        transferReportToggle()
                                      }}
                                    >
                                      Transfer To
                                    </DropdownItem>
                                  )} */}

                                {/* {item.DesUserID ===
                                    localStorage.getItem("id") && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        returnReportToggle()
                                      }}
                                    >
                                      Return Report
                                    </DropdownItem>
                                  )} */}
                                {/* {item.ReportStatus === "Waiting for Client" &&
                                    (userPermissions.R2.G === "1" ||
                                      userPermissions.R4.G === "1" ||
                                      userPermissions.R24.P === "2" ||
                                      userPermissions.R24.P === "3" ||
                                      userPermissions.R24.P === "4" ||
                                      userPermissions.R24.G === "1") && 
                                      (
                                      <DropdownItem
                                        onClick={() => {
                                          setSelectedItem(item)
                                          toggleClientResponse()
                                        }}
                                      >
                                        Communication Response
                                      </DropdownItem>
                                    )} */}
                                {/* {item.ReportStatus === "Waiting for Document" &&
                                    (userPermissions.R2.G === "1" ||
                                      userPermissions.R4.G === "1" ||
                                      userPermissions.R24.P === "2" ||
                                      userPermissions.R24.P === "3" ||
                                      userPermissions.R24.P === "4" ||
                                      userPermissions.R24.G === "1") && 
                                      (
                                      <DropdownItem
                                        onClick={() => {
                                          setSelectedItem(item)
                                          toggleClientDocument()
                                        }}
                                      >
                                        Communication Document
                                      </DropdownItem>
                                    )} */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {reportData.length === 0 && (
                    <LoadingInsider type="spin" color="gray" />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {reportData.length > 1 && (
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          )}

          {/* ********************* View Report *************************** */}
          {viewModal && (
            <ViewRD3Report
              viewToggle={viewToggle}
              selectedItem={selectedItem}
              userPermissions={userPermissions}
              setAuth={setAuth}
              editing={editing}
              setEditing={setEditing}
              cleanReport={cleanReport}
            />
          )}
          {/********************** AsignUser & Change response *****************************/}
          {asign && (
            <AsignRole
              toggle={toggleAsignUser}
              isOpen={asign}
              data={asignData}
              async={async}
            />
          )}
          {/* *********** Communication Data **************** */}
          {CommunicationModal && (
            <Comunication
              toggle={toggleCommunicationData}
              isOpen={CommunicationModal}
              data={CommunicationData}
            />
          )}
          {/***************** Auto approved *****************************/}
          <ApproveMainModal
            approvedModal={approvedModal}
            toggleApproved={toggleApproved}
            type={3}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RD3Reports
