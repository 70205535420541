import axios from "axios"
import { update } from "lodash"
import React, { useState, useEffect } from "react"
import { Container, Button, FormGroup, Modal } from "reactstrap"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

function ImagePicker({
  props,
  viewImageFullOptions,
  toggleImageFullOptions,
  name,
  target,
  value,
  StageID,
  type,
  sendToApi,
  data,
}) {
  
  const [allImages, setAllImages] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [unselectedFiles, setUnSelectedFiles] = useState([])

  const [deletedImages, setDeleteImages] = useState([])
  const [dropzone, setDropzone] = useState(false)
  

  // for view image
  const [isOpen, setIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const handleImageClick = data => {
    setCurrentImage(
      typeof data === "object" && data instanceof Blob
        ? URL.createObjectURL(data)
        : data
    )
    setIsOpen(true)
  }

  useEffect(() => {
    if (value) {
      const images = value || []      
      setSelectedFiles(images)
      setAllImages(images)
    }
  }, [viewImageFullOptions])

  // send files
  function sendFiles() {
    let answerArray = selectedFiles.filter(item => typeof item == "string")

    const data = new FormData()
    data.append("PerToken", localStorage.getItem("token"))
    data.append("PerUserID", localStorage.getItem("id"))
    data.append("PerRoleID", localStorage.getItem("userRoleID"))
    data.append("StageID", StageID)
    data.append("Ticket", "RD0")

    for (let i = 0; i < selectedFiles.length; i++) {
      if (typeof selectedFiles[i] == "object") {
        data.append("file[]", selectedFiles[i])
      }
    }

    axios
      .post("https://rd0test.cpvarabia.com/api/UploadImages", data)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        let resArray = []
        res.data &&
          Object.entries(res.data).forEach(res => {
            res[1] && resArray.push(res[1])
          })
        answerArray = [...answerArray, ...resArray]
        props && props.setFieldValue(name, answerArray)

        setAllImages([...allImages, ...answerArray])

        sendToApi && sendData(answerArray)
        onClose("submit", name, allImages)
      })
      .catch(error => {})
  }

  // delete image
  let deletedImagesobject = { FileName: deletedImages, Target: target }
  const onClose = () => {
    let answerArray = unselectedFiles.filter(item => typeof item == "string")
    setDeleteImages(answerArray)
    if (deletedImages.length > 0) {
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        ...deletedImagesobject,
        StageID: "10",
        Ticket: "RD0",
      }
      axios
        .post("https://rd0test.cpvarabia.com/api/DeleteFile", formData)
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          
          toggleImageFullOptions()
        })
        .catch(err => {})
    } else {
      setAllImages([])
      toggleImageFullOptions()
    }
  }

  const handleFileChange = e => {
    const files = Array.from(e.target.files)
    setSelectedFiles([...selectedFiles, ...files])
    setAllImages([...allImages, ...files])
  }

  const handleClick = async source => {
    // image is selected and we need to remove it from the list
    if (selectedFiles.includes(source)) {
      setSelectedFiles(selectedFiles.filter(item => item !== source))
      setUnSelectedFiles([...unselectedFiles, source])
    } else {
      setSelectedFiles([...selectedFiles, source])
      setUnSelectedFiles(unselectedFiles.filter(item => item !== source))
    }
  }

  // submit
  const onsubmit = () => {
    sendFiles()
  }

  // drop and drag
  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
    setDropzone(true)
  }
  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
    setDropzone(false)
  }
  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    const files = Array.from(e.dataTransfer.files)
    setSelectedFiles([...selectedFiles, ...files])
    setAllImages([...allImages, ...files])
    setDropzone(false)
  }

  // handle paste image
  const handlePaste = event => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (item.kind === "file") {
        const blob = item.getAsFile()
        const file = new File([blob], "pasted-file.png", { type: blob.type })
        handleFileChange({ target: { files: [file] } })
      }
    }
  }

  // send Data

  const sendData = answerArray => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: data.ProjectID,
      TicketID: data.TicketID,
      Attached: answerArray,
    }

    axios
      .post("https://rd0test.cpvarabia.com/api/EditTicket", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        setEditing(!editing)
        // newData()
        if (submitANewTicket === "submitANewTicket") {
          setNewTicketData(res.data)
          ToggleviewTicket()
          ToggleviewNewTicket()
        } else {
          ToggleviewTicket()
          viewNewTicketModal === True && ToggleviewNewTicket()
        }
      })
      .catch(err => console.log(err))
  }

  document.addEventListener("paste", handlePaste)

  return (
    <Modal
      isOpen={viewImageFullOptions}
      toggle={toggleImageFullOptions}
      className="modal-lg"
    >
      <div className="m-auto">
        <div
          className="m-auto mt-3 d-flex flex-wrap justify-content-center"
          style={{ maxWidth: "684px" }}
        >
          {/* view image */}
          {allImages.map((data, index) => {
            return (
              <div key={index}>
              <div  className="d-flex justify-content-around mt-3">
                <div
                  className={`img-card mx-2 position-relative ${
                    selectedFiles.includes(data)
                      ? "border border-2 border-primary mt-2 shadow-lg p-1 mb-1 bg-body-blue rounded"
                      : "border border-2 border-light mt-2 p-1 mb-1 bg-body-secondary rounded"
                  } `}
                  onClick={() =>type != "view" && handleClick(data)}
                >
                  <img
                    style={{ width: "200px", height: "200px" }}
                    alt="Thumbnail"
                    src={
                      typeof data === "object" && data instanceof Blob
                        ? URL.createObjectURL(data)
                        : data
                    }
                   
                  />
                  {selectedFiles.includes(data) ? (
                    <div className="img-checked">
                      <i
                        className="bx bxs-check-circle bx-sm position-absolute"
                        style={{ color: "blue", top: "5px", right: "5px" }}
                      ></i>
                    </div>
                  ) : null}
                  
                </div>

              </div>
          
              <div className="d-flex justify-content-center"  onClick={() => handleImageClick(data)} >
                    <i
                      className="bx bxs-show bx-sm position-absolute color-secondary "
                     
                    ></i>
                  </div>
              </div>
              
            )
          })}

          {type != "view" && (
            <div className="d-flex justify-content-around mt-3">
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileChange}
                onPaste={handlePaste}
                accept="image/*"
                multiple
              />
              <label
                htmlFor="fileInput"
                className={`new-image-picker d-flex justify-content-center align-items-center mx-2 mt-2 p-1 mb-1 rounded ${
                  dropzone ? "dropzone-active" : ""
                }`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <i className="bx bx-plus-circle"></i>
              </label>
            </div>
          )}
          {isOpen && (
            <Lightbox
              mainSrc={currentImage}
              onCloseRequest={() => setIsOpen(false)}
              // Optional: Add zoom functionality
              enableZoom={true}
            />
          )}
        </div>
      </div>
      <FormGroup className="d-flex ms-auto col-6 mt-5 justify-content-around">
        <Button
          type="button"
          onClick={() => {
            onClose()
          }}
          className=" me-2 bg-secondary"
        >
          Close
        </Button>
     {type != "view" &&   <Button
          type="button"
          onClick={() => {
            onsubmit()
          }}
          className=" me-2 bg-primary"
        >
          Submit
        </Button>}
      </FormGroup>
    </Modal>
  )
}

export default ImagePicker

{
  /* 
   const [viewImageFullOptions, setViewImageFullOptions] = useState(false)
  const toggleImageFullOptions = () => {
    setViewImageFullOptions(!viewImageFullOptions)
  }
  
  <ImageFullOptions
        viewImageFullOptions={viewImageFullOptions}
        toggleImageFullOptions={toggleImageFullOptions}
        props={props}
        name={`${Qitem.AnswerType}${Qitem.QID}-image${answerId}`}
        target={"Image"}
        setAuth={setAuth}
      />
      
  */
}
