import React, { useEffect, useState } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import axios from "axios"
//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import PaginationComponent from "common/PaginationComponent"
import FilterUsers from "./FilterUsers"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import AddUser from "./AddUser"
import Manage from "./manage/Manage"
import { usePrevious } from "common/CustomHooks"



const EndUser = () => {

  //meta title
  document.title = "EUA Accounts | DomApp - React Admin & Dashboard Template"

  const [selectedUser, setSelectedUser] = useState()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // ********************* Add User Modal*****************************
  const [addUserModal, setAddUserModal] = useState(false)
  const addUserToggle = () => {
    setAddUserModal(!addUserModal)
  }

  // ********************* Edit User Modal*****************************
  const [ManageModal, setManageModal] = useState(false)
  const ManageToggle = () => {
    setManageModal(!ManageModal)
  }

  // ********** Filter Section *********************
  const initialFilteredValues = {
    mobile: "",
    userName: "",
    referenceNumber: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //------ checked rows -------//
  const [checkedRows, setCheckedRows] = useState([])
  const checkbox = item => {
    setCheckedRows([item, ...checkedRows])
  }

  const [editing, setEditing] = useState(false)
  const async = () => {
    setEditing(!editing)
  }
  const [UserData, setUserData] = useState([])

  const prevFilteredValues = usePrevious(filteredValues)

  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
    }
    axios
      .get(`https://althameen.net/dashboard/v1/users`,{params:{...filteredValues,page}})
      .then(res => {
        if (res.data.data && res.data.data.length > 0) {
          setUserData(res.data.data)
          setTotalPages(res.data.meta.last_page)
        } else {
          setUserData([])
        }
      })
      .catch(error => {
        console.log(error)
      })

      // setSelectedUser(prev => {
      //   const selected = res.data.data.find(item => item.id === prev?.id);
      //   return selected ? selected : prev;
      // });
  }, [editing, page, filteredValues])




  

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }


  // ********************************************* //
  // not allowed views
  if (
    userPermissions.R9.P !== "1" &&
    userPermissions.R9.P !== "2" &&
    userPermissions.R9.P !== "3" &&
    userPermissions.R9.P !== "4" &&
    userPermissions.R9.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="EUA Accounts " breadcrumbItem="EUA Accounts " />
            {/*************** New User Section *************/}
            {
              <div className="d-flex justify-content-between mb-4">
                <button
                  className="btn btn-primary py-2 px-4 me-2 ms-auto"
                  onClick={() => addUserToggle()}
                >
                  Add User
                </button>
                {/* </Link> */}
              </div>
            }

            {/*************** Filter Section *************/}
            <FilterUsers
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
            />

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Mobile</th>
                          <th scope="col">Owner Projects</th>
                          <th scope="col">Managed Projects</th>
                          <th scope="col">Viewed Projects</th>
                          {/* <th scope="col">Last visit</th> */}
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      {/***********table body *************************************/}
                      <tbody>
                        {UserData.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <input
                                type="checkbox"
                                onClick={() => {
                                  checkbox(item)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>
                              <span>{item.mobile_number}</span>
                            </td>
                            <td ><span className={item.role_id=="1"&&"badge bg-warning"}>{item.owner_projects_count} </span></td>
                            <td><span className={item.role_id=="2"&&"badge bg-warning"}>{item.managed_projects_count}</span></td>
                            <td> <span className={item.role_id=="3"&&"badge bg-warning"}>{item.viewed_projects_count}</span></td>
                            <td>
                              <Button
                                className="btn btn-primary "
                                style={{ cursor: "pointer" }}
                                onClick={e => {
                                  setSelectedUser(item)
                                  ManageToggle()
                                }}
                              >
                                Manage
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>

            {/* ****************** Add User *************************** */}
            {addUserModal && (
              <AddUser
                addUserToggle={addUserToggle}
                async={async}
                setEditing={setEditing}
                setAuth={setAuth}
              />
            )}

            {/* ****************** Edit User *************************** */}
            {ManageModal && (
              <Manage
                ManageToggle={ManageToggle}
                selectedUser={selectedUser}
                editing={editing}
                setEditing={setEditing}
                setAuth={setAuth}
                UserData={UserData}
                page={page}
                async={async}
              />
            )}

            {/* **************** Pagination ************************** */}
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(EndUser)
